import React from 'react';
import {
  CodeSnippet,
  Link,
  List,
  PageNavigation,
  PageNavigationLink,
  Paragraph,
  PropList,
  PropListItem,
  PlatformTab,
  Section,
} from 'docComponents';
import { Text } from 'hudl-uniform-ui-components';
import { Checkbox, CheckboxGroup } from 'hudl-rn-uniform-ui';

const checkboxSnippet = require('raw-loader!../../../../../data/snippets/rn-checkbox.example');
const checkboxGroupSnippet = require('raw-loader!../../../../../data/snippets/rn-checkbox-group.example');

const ReactNativeTab = () => {
  return (
    <PlatformTab>
      <PageNavigation>
        <PageNavigationLink>Single Checkbox</PageNavigationLink>
        <PageNavigationLink>Checkbox Group</PageNavigationLink>
      </PageNavigation>
      <Section title="Single Checkbox">
        <CodeSnippet
          scope={{ React: React, Checkbox: Checkbox }}
          code={checkboxSnippet}
          platform="react-native"
          gitHubLink="forms/checkbox"
        />

        <Section title="Props">
          <PropList>
            <PropListItem name="size" types={['oneOf', 'string']}>
              <Text>Determines the checkbox's size.</Text>
              <List type="unordered">
                <li>
                  <code>xsmall</code>
                </li>
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code> (default)
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>

            <PropListItem name="label" types={['string']}>
              <Text>The label that appears to the right of the checkbox.</Text>
            </PropListItem>

            <PropListItem name="value" types={['string', 'number', 'bool']}>
              <Text>The value bound to the input.</Text>
            </PropListItem>

            <PropListItem name="checked" types={['bool']}>
              <Text>Determines whether the checkbox is checked.</Text>
            </PropListItem>

            <PropListItem name="disabled" types={['bool']}>
              <Text>Determines whether the checkbox is disabled.</Text>
            </PropListItem>

            <PropListItem name="theme" types={['oneOf', 'string']}>
              <Text>Determines the theme of the checkbox.</Text>
              <List type="unordered">
                <li>
                  <code>light</code> (default)
                </li>
                <li>
                  <code>dark</code>
                </li>
              </List>
            </PropListItem>

            <PropListItem name="testID" types={['string']}>
              <Text>
                A unique string to control this checkbox in automated tests.
              </Text>
            </PropListItem>

            <PropListItem name="accessibilityLabel" types={['string']}>
              <Text>
                Overrides the text that's read by the screen reader when the user interacts with the checkbox.
              </Text>
            </PropListItem>

            <PropListItem name="onPress" types={['func']}>
              <Text>
                Responds to the checkbox being tapped. The value
                of the checkbox is passed.
              </Text>
            </PropListItem>
          </PropList>
        </Section>

        <Section title="Notes">
          <List type="unordered">
            <li>
              Do not pass the <code>checked</code> attribute if you're using
              this component within a CheckboxGroup. Instead, use that
              component's <code>valuesChecked</code> prop.
            </li>
          </List>
        </Section>
      </Section>
      <Section title="Checkbox Group">
        <Paragraph>
          The Checkbox Group is the parent element of related checkboxes from
          which a user is expected to choose one of the options.
        </Paragraph>

        <CodeSnippet
          scope={{
            React: React,
            Checkbox: Checkbox,
            CheckboxGroup: CheckboxGroup,
          }}
          code={checkboxGroupSnippet}
          platform="react-native"
          gitHubLink="forms/checkbox-group"
        />

        <Section title="Props">
          <PropList>
            <PropListItem name="size" types={['oneOf', 'string']}>
              <Text>Determines the checkbox's size.</Text>
              <List type="unordered">
                <li>
                  <code>xsmall</code>
                </li>
                <li>
                  <code>small</code>
                </li>
                <li>
                  <code>medium</code> (default)
                </li>
                <li>
                  <code>large</code>
                </li>
              </List>
            </PropListItem>

            <PropListItem name="header" types={['string']}>
              <Text>The text that appears above the group of checkboxes.</Text>
            </PropListItem>

            <PropListItem name="valuesChecked" types={['array']}>
              <Text>The values checked in the group.</Text>
            </PropListItem>

            <PropListItem name="required" types={['bool']}>
              <Text>
                Determines whether the required text appears next to the header.
              </Text>
            </PropListItem>

            <PropListItem name="disabled" types={['bool']}>
              <Text>
                Determines whether the entire checkbox group is disabled.
              </Text>
            </PropListItem>

            <PropListItem name="space" types={['oneOf', 'string']}>
              <Text>
                Any of the{' '}
                <Link href="/resources/code-variables/space?reactnative">
                  space variables
                </Link>
                .
              </Text>
            </PropListItem>

            <PropListItem name="theme" types={['oneOf', 'string']}>
              <Text>Determines the theme of the checkbox.</Text>
              <List type="unordered">
                <li>
                  <code>light</code> (default)
                </li>
                <li>
                  <code>dark</code>
                </li>
              </List>
            </PropListItem>

            <PropListItem name="testID" types={['string']}>
              <Text>
                A unique string to control this checkbox group in automated tests.
              </Text>
            </PropListItem>

            <PropListItem name="accessibilityLabel" types={['string']}>
              <Text>
                Overrides the text that's read by the screen reader when the user interacts with the checkbox group.
              </Text>
            </PropListItem>

            <PropListItem name="onPress" types={['func']}>
              <Text>
                Responds to the checkbox being checked or unchecked. The value of
                the clicked checkbox is passed.
              </Text>
            </PropListItem>
          </PropList>
        </Section>

        <Section title="Notes">
          <List type="unordered">
            {/* eslint-disable max-len */}
            <li>
              Since this is a stateless component, you'll have to keep track of
              the selected checkboxes via your app's state. Use the array{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/concat">
                concat
              </Link>{' '}
              and{' '}
              <Link href="https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Array/filter">
                filter
              </Link>{' '}
              functions to easily add or remove values.
            </li>
            {/* eslint-enable max-len */}
          </List>
        </Section>
      </Section>
    </PlatformTab>
  );
};

export default ReactNativeTab;
